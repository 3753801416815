import { config } from "@config/config";
import { FileFragment, useGetConfigurationQuery } from "@gql-schema";
import { resizerImageUrl } from "@uxf/core/utils/resizer";
import Head from "next/head";
import { useRouter } from "next/router";

interface HeadMetaTagsProps {
    title: string;
    description: string;
    image?: FileFragment | null | undefined;
}

function HeadMetaTags(props: HeadMetaTagsProps) {
    const { asPath } = useRouter();
    const { data: configData } = useGetConfigurationQuery();
    if (!configData) {
        return <div />;
    }

    const configuration = configData.configuration;

    const imageUrl = resizerImageUrl(props.image ?? configuration.headingImage ?? null, 858, 720, {});

    const favicon = config.FRONTEND_URL.includes("tanvald") ? "favicon-tanvald" : "favicon";
    const webmanifest = config.FRONTEND_URL.includes("tanvald") ? "site-tanvald.webmanifest" : "site.webmanifest";

    return (
        <Head>
            <title>{props.title}</title>
            <meta charSet="utf-8" />
            <meta name="title" content={props.title} />
            <meta name="description" content={props.description} />
            <meta property="og:title" content={props.title} />
            <meta property="og:description" content={props.description} />
            <meta property="og:image" content={`${config.FRONTEND_URL}${imageUrl}`} />
            <meta property="og:image:width" content="858" />
            <meta property="og:image:height" content="720" />
            <meta property="og:url" content={config.FRONTEND_URL + asPath} />
            <meta property="og:site_name" content={`${configuration.h1Title} - ${configuration.h1Subtitle}`} />
            <meta property="og:type" content="website" />
            <link rel="preload" href={`${config.FRONTEND_URL}${imageUrl}`} as="image" />
            <link rel="preconnect" href="https://www.googletagmanager.com" />
            <link rel="preconnect" href="https://www.google-analytics.com" />
            <link rel="preconnect" href="https://sentry-relay.uxf.cz" />

            <link rel="apple-touch-icon" sizes="180x180" href={`/${favicon}/apple-touch-icon.png`} />
            <link rel="icon" type="image/png" sizes="32x32" href={`/${favicon}/favicon-32x32.png`} />
            <link rel="icon" type="image/png" sizes="16x16" href={`/${favicon}/favicon-16x16.png`} />
            <link rel="manifest" href={`/${webmanifest}`} crossOrigin="use-credentials" />
            <link rel="mask-icon" href={`/${favicon}/safari-pinned-tab.svg`} color="#ffffff" />
            <link rel="shortcut icon" href={`/${favicon}/favicon.ico`} />
            <meta name="apple-mobile-web-app-title" content={`${configuration.h1Title}`} />
            <meta name="application-name" content={`${configuration.h1Title}`} />
            <meta name="msapplication-TileColor" content="#ffc40d" />
            <meta name="msapplication-config" content={`/${favicon}/browserconfig.xml`} />
            <meta name="theme-color" content="#ffffff" />
        </Head>
    );
}

export default HeadMetaTags;
