interface Props {
    title: string;
}

function H2(props: Props) {
    return (
        <h2 className="text-center text-3xl font-semibold tracking-tight text-gray-900 sm:text-4xl">{props.title}</h2>
    );
}

export default H2;
