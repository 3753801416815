import { isBuildTime } from "@shared/utils/is-build-time";
import { GetStaticProps } from "next";

export const basicStaticProps = function (getStaticProps: GetStaticProps): GetStaticProps {
    return async (...params) => {
        if (isBuildTime()) {
            return {
                notFound: true,
                revalidate: 10,
            };
        }

        try {
            return await getStaticProps(...params);
        } catch (e) {
            // eslint-disable-next-line no-console
            console.error("Error fetching data in getStaticProps: ", e);

            return {
                notFound: true,
                revalidate: 10,
            };
        }
    };
};
