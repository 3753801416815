import { useGetConfigurationQuery } from "@gql-schema";
import { resizerImageUrl } from "@uxf/core/utils/resizer";
import { FC } from "react";

const MainPartners: FC = () => {
    const { data: configData } = useGetConfigurationQuery();
    if (!configData) {
        return <div />;
    }

    const sponsors = configData.configuration.sponsors;

    return (
        <div className="bg-indigo-200/25">
            <div className="mx-auto max-w-7xl px-4 py-16 sm:px-6 sm:py-12 lg:px-8">
                <div className="lg:grid lg:grid-cols-2 lg:gap-8">
                    <h2 className="mx-auto mt-3 max-w-md text-center text-3xl font-semibold text-indigo-900 lg:max-w-xl lg:text-left">
                        Hlavní finanční partneři
                    </h2>
                    <div className="mt-8 flow-root self-center lg:mt-0">
                        <div className="-mt-4 flex flex-wrap justify-between lg:-ml-4">
                            {sponsors
                                .filter((partner) => partner.mainSponsor)
                                .map((partner) => {
                                    /* eslint-disable react/jsx-no-target-blank */
                                    return (
                                        <a
                                            key={partner.url}
                                            href={partner.url}
                                            target="_blank"
                                            title={partner.name}
                                            className="mx-4 mt-4 flex shrink-0 grow justify-center lg:ml-4 lg:grow-0"
                                        >
                                            <img
                                                className="h-16 w-60"
                                                src={resizerImageUrl(partner.logo ?? null, 240, 64, {}) ?? undefined}
                                                alt={partner.name}
                                            />
                                        </a>
                                    );
                                    /* eslint-enable react/jsx-no-target-blank */
                                })}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MainPartners;
